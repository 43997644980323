// Polyfills
// These modules are what's in angular 2 bundle polyfills so don't include them
// import 'es6-shim';
// import 'es6-promise';
// import 'reflect-metadata';

// CoreJS has all the polyfills you need

// import 'core-js';
// require('zone.js/dist/zone');
// require('zone.js/dist/long-stack-trace-zone');

import {environment} from "./environments/environment";

import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/reflect';

import 'core-js/es7/reflect';
import 'core-js/es6/';
import 'reflect-metadata';

import 'zone.js/dist/zone';
import '@angular/localize/init';

if (environment.production) {
    // Production
}
else {
    // Development
    Error["stackTraceLimit"] = Infinity;
}